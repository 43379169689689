<template>
  <div class="dashboard">
    <!-- 头部 -->
    <el-header>
      <div class="title">后台管理系统</div>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <i class="el-icon-setting"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>

    <!-- 侧边栏 -->
    <el-aside width="200px">
      <el-menu :default-active="defaultActive" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b"
        @select="handleMenuSelect">
        <el-menu-item index="1">
          <i class="el-icon-s-check"></i>
          认证审核管理
        </el-menu-item>
        <el-menu-item index="2">
          <i class="el-icon-schedule"></i>
          预约管理
        </el-menu-item>
        <el-menu-item index="3">
          <i class="el-icon-schedule"></i>
          小区开放认证管理
        </el-menu-item>
      </el-menu>
    </el-aside>

    <!-- 主内容区 -->
    <el-main>
      <router-view></router-view>
    </el-main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultActive: '1' // 默认选中的菜单项
    };
  },
  methods: {
    handleMenuSelect(index) {
      this.defaultActive = index;
      // 根据选中的菜单项跳转到相应的页面
      switch (index) {
        case '1':
          this.$router.push({ path: '/authentication' });
          break;
        case '2':
          this.$router.push({ path: '/appointment' });
          break;
        case '3':
          this.$router.push({ path: '/community' });
          break;
      }
    },
    logout() {
      // 执行退出登录的操作
    }
  }
};
</script>

<style>
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: 20px;
  color: #fff;
  margin-right: 20px;
}

.el-aside {
  background-color: #545c64;
}

.el-menu {
  height: 100%;
}

.el-header {
  background-color: #409EFF;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.el-main {
  padding: 20px;
}
</style>